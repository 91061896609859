import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { createGlobalStyle } from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const GlobalStyle = createGlobalStyle`
  .slide-enter {
    transform: translateY(-100%);
    opacity: 0;
  }
  .slide-enter-active {
    transform: translateY(0);
    opacity: 1;
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
  }
  .slide-exit {
    transform: translateY(0);
    opacity: 1;
  }
  .slide-exit-active {
    transform: translateY(100%);
    opacity: 0;
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
  }
`;

const HelloBar = () => {
  const [hellobars, setHellobars] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    // Check if the hellobar has been closed for this session
    const isClosed = sessionStorage.getItem("hellobarClosed");
    if (isClosed) return;

    axios.get("/api/hellobar/v2").then((res) => {
      setHellobars(res?.data?.hellobar);
    });
  }, []);

  useEffect(() => {
    if (hellobars?.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % hellobars?.length);
      }, 8000);

      return () => clearInterval(interval);
    }
  }, [hellobars]);

  useEffect(() => {
    if (hellobars?.length > 0) {
      const currentHellobar = hellobars[currentIndex];
      if (currentHellobar && currentHellobar.isTimerEnabled) {
        const timer = setInterval(() => {
          const now = new Date().getTime();
          const targetDate = new Date(currentHellobar.countdownDate).getTime();
          const difference = targetDate - now;

          if (difference > 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeLeft({ days, hours, minutes, seconds });
          } else {
            clearInterval(timer);
            setTimeLeft(null);
          }
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [hellobars, currentIndex]);

  const handleClose = () => {
    sessionStorage.setItem("hellobarClosed", "true");
    setHellobars([]);
  };

  if (!hellobars?.length) return null;

  const currentHellobar = hellobars[currentIndex];

  const renderCountdown = () => {
    if (!timeLeft) {
      return <span>{currentHellobar.messageAfterCountDown}</span>;
    }
    return (
      <>
        <span>{currentHellobar.message}</span>{" "}
        <TimerText>
          {timeLeft.days} days {timeLeft.hours} hrs {timeLeft.minutes} min {timeLeft.seconds} sec
        </TimerText>
      </>
    );
  };

  return (
    <>
      <GlobalStyle />
      <AlertBar>
        <TransitionGroup component={null}>
          <CSSTransition key={currentIndex} classNames="slide" timeout={500}>
            <div>
              {currentHellobar.showCloseButton && <CloseButton onClick={handleClose}>×</CloseButton>}
              {currentHellobar.isTimerEnabled ? renderCountdown() : currentHellobar.message}
              {currentHellobar.isButtonEnabled && (
                <HelloButton href={currentHellobar.url} target="_blank">
                  {currentHellobar.buttonText}
                </HelloButton>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </AlertBar>
    </>
  );
};

const AlertBar = styled.div`
  background-color: #ff4b4b;
  color: white;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  padding: 1rem 1rem; /* Increased padding for more vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transition: opacity 0.5s ease-in-out;
`;

const TimerText = styled.span`
  color: yellow;
  margin-left: 0.5rem;
`;

const HelloButton = styled.a`
  background-color: white;
  color: #ff4b4b !important;
  border: none;
  border-radius: 8px;
  font-size: 14.4px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  line-height: 1.2;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin-left: 1rem;

  &:hover {
    opacity: 0.9;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;

  &:hover {
    color: #ddd;
  }
`;

export default HelloBar;
